import { Autocomplete, Box, TextField } from '@mui/material';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const PaymentsCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		formState: { errors },
		control,
	} = useForm();

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					type="number"
					{...register('user_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.user_id}
					helperText={errors.user_id?.message ? `${errors.user_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="User Id"
					name="user_id"
					autoFocus
				/>
				<TextField
					type="number"
					{...register('amount', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.amount}
					helperText={errors.amount?.message ? `${errors.amount?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Amount"
					name="amount"
					autoFocus
				/>
				<TextField
					type="number"
					{...register('plan_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.plan_id}
					helperText={errors.plan_id?.message ? `${errors.plan_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Plan Id"
					name="plan_id"
				/>
				<Controller
					control={control}
					name="duration_type"
					rules={{
						required: 'Required',
					}}
					defaultValue={'month'}
					render={({ field }) => (
						<Autocomplete
							options={['day', 'month', 'year']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Duration Type"
									margin="normal"
									variant="outlined"
									error={!!errors.duration_type}
									helperText={errors.duration_type?.message ? `${errors.duration_type?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<TextField
					{...register('duration_time', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.chat_lang}
					helperText={errors.duration_time?.message ? `${errors.duration_time?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Duration Time"
					name="duration_time"
					type="number"
					autoFocus
				/>

				<TextField
					{...register('payment_date')}
					error={!!errors.payment_date}
					helperText={errors.payment_date?.message ? `${errors.payment_date?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Payment Date"
					name="payment_date"
					type="date"
					focused
				/>
				<TextField
					{...register('effective_date')}
					error={!!errors.effective_date}
					helperText={errors.effective_date?.message ? `${errors.effective_date?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Effective Date"
					name="effective_date"
					type="date"
					focused
				/>
				<TextField
					{...register('buffer_days', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.buffer_days}
					helperText={errors.buffer_days?.message ? `${errors.buffer_days?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Buffer Days"
					name="buffer_days"
					type="number"
					autoFocus
				/>
			</Box>
		</Create>
	);
};
